<template>
    <NsiForm />
</template>

<script>
import NsiForm from '@/components/Nsi/NsiForm'

export default {
    name: "CreateNsi",
    components: {
        NsiForm
    },
    data: () => ({
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Перечень запросов на добавление НСИ', link: '/nsi-list' },
            { title: 'Создание запроса' }
        ]
    })
}
</script>
